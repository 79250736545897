import React from "react";
import instagram from "../assets/socialIcons/instagram2.svg";
import facebook from "../assets/socialIcons/facebook2.svg";
import telephone from "../assets/socialIcons/phone2.svg";
import localisation from "../assets/socialIcons/localisation2.svg";
import FooterContactForm from "./FooterContactForm";
import scrolltop from "../assets/shapesImages/scrollTop.svg";
import scrollMobile from "../assets/shapesImages/scrollMobile.svg";
const Footer = () => {
  return (
    <>
      <div className="scroll_top_sec">
        <img
          src={scrolltop}
          className="scroll_top_arrow"
          alt="scroll_top"
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
        />
      </div>
      <div className="footer">
        <div className="footer_contact">
          <div
            style={{
              width: "100%",
              height: "100%",
              display: "flex",
              alignItems: "center",
              justifyContent: "center",
            }}
          >
            <FooterContactForm />
            {window.innerWidth < 768 && (
              <div className="scroll_mobile">
                <img
                  src={scrollMobile}
                  alt="scroll_top"
                  onClick={() => {
                    window.scrollTo({
                      top: 0,
                      behavior: "smooth",
                    });
                  }}
                />
              </div>
            )}
          </div>

          <p className="copyright-mobile">
            Tous les droits sont réservés pour SOFT CACTUS 2024
          </p>
        </div>
        <div className="footer_coords">
          <div className="footer_content">
            <h2>POUR NOUS JOINDRE</h2>
            <p>Km 9 Route Moulay Thami, Casablanca 20250</p>
            <a href="tel:+212660343555">
              <p>06 60 34 35 55</p>
            </a>
            <a href="mailto:contact@sogerev.com">
              <p>contact@sogerev.com</p>
            </a>
            <div className="footer_socials">
              <a
                href="https://www.instagram.com/sogerev.inter?igsh=Yng4eWlkdWsxeGZv"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={instagram} alt="insta" />
              </a>
              <a
                href="https://www.facebook.com/share/yzpPc7Pa54BAxtYn/?mibextid=qi2Omg"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={facebook} alt="fb" />
              </a>
              <a href="tel:+212660343555">
                <img src={telephone} alt="tele" />
              </a>
              <a
                href="https://maps.app.goo.gl/XtphjYrEvMEf2xkE7"
                target="_blank"
                rel="noopener noreferrer"
              >
                <img src={localisation} alt="geo" />
              </a>
            </div>
            <p className="copyright-desktop">
              Tous les droits sont réservés pour SOFT CACTUS 2024
            </p>
          </div>
        </div>
      </div>
    </>
  );
};

export default Footer;
