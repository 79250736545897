import React, { useEffect } from "react";
import TitlePageHolder from "../../components/TitlePageHolder";
import "./contact.css";
import ContactForm from "../../components/ContactForm";
import telephone from "../../assets/socialIcons/phone3.svg";
import instagram from "../../assets/socialIcons/instagram3.svg";
import facebook from "../../assets/socialIcons/facebook3.svg";
import email from "../../assets/socialIcons/email.svg";
import localisation from "../../assets/socialIcons/localisation3.svg";
import Cubic from "../../assets/shapesImages/Cubic.png";
import Cubico from "../../assets/shapesImages/Cubico.png";
import MobileNav from "../../components/MobileNav";
const Contact = () => {
  useEffect(() => window.scrollTo(0, 0), []);

  return (
    <>
      {window.innerWidth < 768 && <MobileNav />}
      <TitlePageHolder
        title="Contactez-nous"
        paragraph="La qualité qui transcende les frontières, sogerev vous offre le monde en revêtements."
      />
      <div className="contact-container">
        <img src={Cubic} className="contact-cubic" alt="cubic" />
        <img src={Cubico} className="contact-cube" alt="cube" />
        <div className="contact-info">
          <h2>Contactez-nous</h2>
          <p>
            Pour nous contacter chez SOGEREV, remplissez le formulaire,
            appelez-nous, ou envoyez un email.
          </p>
          <h4>Contact Info :</h4>
          <div className="contact-socials">
            <a href="tel:+212660343555">
              <img src={telephone} alt="tele" />
            </a>
            <p>
              Numéro de téléphone :{" "}
              <a href="tel:+212660343555" className="contact-link">
                06 60 34 35 55
              </a>
            </p>
          </div>
          <div className="contact-socials">
            <a href="mailto:contact@sogerev.com">
              <img src={email} alt="email" />
            </a>
            <p>
              Email :{" "}
              <a href="mailto:contact@sogerev.com" className="contact-link">
                contact@sogerev.com
              </a>
            </p>
          </div>
          <div className="contact-socials">
            <a
              href="https://maps.app.goo.gl/XtphjYrEvMEf2xkE7"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={localisation} alt="localisation" />
            </a>
            <p>
              Adresse :{" "}
              <a
                href="https://maps.app.goo.gl/XtphjYrEvMEf2xkE7"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-link"
              >
                km 9 Route Moulay Thami, Casablanca 20250
              </a>
            </p>
          </div>
          <div className="contact-socials">
            <a
              href="https://www.instagram.com/sogerev.inter?igsh=Yng4eWlkdWsxeGZv"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={instagram} alt="insta" />
            </a>
            <p>
              Instagram :{" "}
              <a
                href="https://www.instagram.com/sogerev.inter?igsh=Yng4eWlkdWsxeGZv"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-link"
              >
                Sogerev inter
              </a>{" "}
            </p>
          </div>
          <div className="contact-socials">
            <a
              href="https://www.facebook.com/share/yzpPc7Pa54BAxtYn/?mibextid=qi2Omg"
              target="_blank"
              rel="noopener noreferrer"
            >
              <img src={facebook} alt="fb" />
            </a>
            <p>
              Facebook :{" "}
              <a
                href="https://www.facebook.com/share/yzpPc7Pa54BAxtYn/?mibextid=qi2Omg"
                target="_blank"
                rel="noopener noreferrer"
                className="contact-link"
              >
                Sogerev inter
              </a>
            </p>
          </div>
        </div>
        <div className="contact-form">
          <ContactForm />
        </div>
      </div>
    </>
  );
};

export default Contact;
